<template>
    <div class="row text-center caixa-foto-vereador">
        <div class="col-12 foto-parlamentar">
            <img :src="urlFoto ? urlFoto : '#'" />
        </div>
        <div class="col-12 sigla-partido" v-show="partido.length > 0">
            <span class="badge badge-pill badge-secondary">{{ partido }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        partido: {
            type: String,
            required: false
        },
        urlFoto: {
            type: String,
            required: false
        }
    }
};
</script>

<style scoped lang="scss">
.caixa-foto-vereador {
    font-size: 1em;
    @media (min-width: 640px) { font-size: 1.2em; }
    @media (min-width: 768px) { font-size: 1.5em; }
    @media (min-width: 1024px) { font-size: 1.8em; }
    @media (min-width: 1280px) { font-size: 2.1em; }
    @media (min-width: 1536px) { font-size: 2.5em; }
    @media (min-width: 2000px) { font-size: 2.8em; }
    @media (min-width: 2400px) { font-size: 3.1em; }
    @media (min-width: 2800px) { font-size: 3.4em; }
    @media (min-width: 3200px) { font-size: 3.7em; }
    @media (min-width: 3600px) { font-size: 4.1em; }
    @media (min-width: 4000px) { font-size: 4.5em; }
}
.foto-parlamentar {
    height: 9em;
    & > img {
        height: 100%;
        width: auto;
        border-radius: 0.4em;
        border: 2px solid rgba(255, 255, 255, 0.25);
        box-shadow: 0 0 0.6em rgba(255, 255, 255, 0.36);
    }

}
.sigla-partido .badge {
    line-height: 1;
    margin-top: 0.5em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    font-size: 1em;
}
</style>
